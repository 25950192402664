import { useEffect, useState, useRef } from 'react'
import { Modal } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
const url = process.env.REACT_APP_BACK_URL

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [verified, setVerified] = useState(false)
  const [canSend, setCanSend] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const recaptchaRef = useRef(null)

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const verify = () => {
    if (recaptchaRef.current && recaptchaRef.current.getValue()) {
      setVerified(true)
      setRecaptchaToken(recaptchaRef.current.getValue())
    }
  }

  useEffect(() => {
    if (name && message && email && verified) {
      setCanSend(true)
    } else {
      setCanSend(false)
    }
  }, [name, message, email, verified])

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (canSend) {
      fetch(`${url}/pub/email`, {
        method: 'post', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name, email, message, "g-recaptcha-response": recaptchaToken
        })
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Su mensaje no se ha enviado")
        }
        return response.json()})
      .then((response) => {
        clearState()
        setModalTitle('Mensaje enviado')
        setModalMessage(response.message)
        showModal()
        recaptchaRef.current.reset()
      }
      )
      .catch((error) => {
        setModalTitle('Error')
        setModalMessage('No se pudo enviar su mensaje')
        showModal()
        recaptchaRef.current.reset()
      })
    }
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contactate con nosotros</h2>
                <p>
                  Rellena el formulario y comunicate con nosotros a traves de email.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        value={name}
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nombre'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        value={email}
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    value={message}
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcTCXgcAAAAADx976-81FNilNVtj9i_xP3v9eV1"
                  onChange={verify}
                />        
                <button style={canSend ? {} : {opacity: '0.5', cursor: 'not-allowed'}} type='submit' className='btn btn-custom btn-lg'>
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informacion de contacto</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Direccion
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefono
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Modal title={modalTitle} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
          <p>{modalMessage}</p>
        </Modal>
      </div>
    {/*<div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2020 Issaaf Kattan React Land Page Template. Design by{' '}
            <a href='http://www.templatewire.com' rel='nofollow'>
              TemplateWire
            </a>
          </p>
        </div>
      </div>*/}
    </div>
  )
}
